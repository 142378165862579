<template>
  <section>
    <div class="stat-body p-1">
      <div class="mb-3" style="display: flex">
        <el-tooltip placement="top-start" :content="component.component_name">
          <p
            class="Title_sty mb-0 fw-300 pl-1"
            :style="{ flexGrow: 1, color: font_color }"
            @click="openTableDialog()"
          >
            {{ name | truncate(hide_options ? 18 : 10, "...") }}
          </p>
        </el-tooltip>
        <div
          v-if="component.compare"
          :style="`float: right;background-color: ${
            getComparedPercentage < 0 ? '#fbc4c4' : '#c2e7b0'
          }; border-radius : 7px; padding : 1px; margin-top : -10px; margin-right: -5px; font-size: 10px; font-weight : bold`"
        >
          <el-tooltip
            :content="
              'Previous ' +
              component.component_name +
              ': ' +
              component.compare_count
            "
            placement="top-start"
          >
            <span
              >{{
                (getComparedPercentage > 0 ? "+" : "") + getComparedPercentage
              }}%
              <el-icon
                :class="
                  getComparedPercentage > 0
                    ? 'el-icon-caret-top'
                    : getComparedPercentage == 0
                    ? 'el-icon-check'
                    : 'el-icon-caret-bottom'
                "
              ></el-icon
            ></span>
          </el-tooltip>
        </div>
        <div class="action_sty" v-if="isIntegrationEdit && hide_options">
          <el-link
            icon="el-icon-edit"
            :underline="false"
            @click="integrationEdit"
            style="margin-right: 5px"
          ></el-link>
        </div>
        <div class="action_sty" v-if="!hide_options">
          <el-link
            icon="el-icon-edit"
            :underline="false"
            @click="editStat"
            style="margin-right: 5px"
          ></el-link>
          <el-link
            icon="el-icon-delete"
            :underline="false"
            @click="deleteStat"
          ></el-link>
        </div>
      </div>
      <div class="d-flex align-items-center" v-if="isApplicationUser">
        <icons
          :iconName="component.icon ? component.icon : 'dashboard'"
          class="iconStyle"
        ></icons>
      </div>
      <div class="d-flex align-items-center" v-else>
        <icons class="iconStyle" :iconName="component.icon"></icons>
      </div>
      <div></div>
      <!-- is_currency -->
      <p
        class="number_c_sty count fw-300"
        @click="openTableDialog()"
        v-if="isCurrencyStat"
        :style="{ color: font_color }"
      >
        {{
          Intl.NumberFormat("en-US", {
            style: "currency",
            currency: component.currency_type ? component.currency_type : "USD",
          }).format(count)
        }}
      </p>
      <p
        v-else-if="component.display_type == 'TIME_STAMP'"
        class="number_c_sty fw-300 fs-10"
        @click="openTableDialog()"
        :style="{ color: font_color }"
      >
        {{ count ? $moment(count).format(component.timestamp_format) : "-" }}
      </p>
      <p
        class="number_c_sty count fs-6 fw-300"
        @click="openTableDialog()"
        :style="{ color: font_color }"
        v-else
      >
        {{ count }}
      </p>
    </div>
    <el-drawer
      :title="component.component_name"
      :visible.sync="openIntegrationEdit"
      direction="rtl"
    >
      <div style="padding: 20px">
        <IntegrationConfiguration
          :addStatsData="component"
          :integrations="integrations"
          :predefinedIntegrations="predefinedIntegrations"
        ></IntegrationConfiguration>
        <br />
        <el-row>
          <el-col xl="24" style="text-align: right">
            <el-button type="primary" size="small" @click="refreshStat"
              >Save</el-button
            >
          </el-col>
        </el-row>
      </div>
    </el-drawer>

    <dialog-component
      :title="component.component_name"
      :visible="tableVisible"
      @before-close="closeDialog"
      :containerMinWidth="showFullTable ? '90vw' : 'auto'"
      :containerHeight="showFullTable ? '100vh' : 'auto'"
      :isShowFooter="false"
    >
      <div
        :style="{
          'max-height': maxHeight,
          'overflow-y': 'auto',
          'scrollbar-width': 'thin',
        }"
      >
        <CustomTable
          v-if="tableVisible && component.display_type == 'MISSING_SLOTS'"
          :data="missingData"
          :columns="columns"
          :actions="actions"
          :component="component"
          :getAllEntities="getAllEntities"
          :getAllCompanyTemplatesData="getAllCompanyTemplatesData"
          :entity_id="
            component && component.other_entity_id
              ? component.other_entity_id.split('#')[0]
              : ''
          "
          :isApplicationUserSide="isApplicationUser"
        >
        </CustomTable>
        <CustomDashboardTable
          v-else-if="tableVisible"
          :component="component"
          :entity_id="entity_id"
          :filter_id="filter_id"
          :customFilters="[]"
          :hide_options="true"
          :isFromApplicationuser="isApplicationUser"
          :hasViewPermission="hasViewPermission"
          :hasEditPermission="hasEditPermission"
          :data_ids="component.missingIds"
          :apply_only_data_ids="component.display_type == 'MISSING_SLOTS'"
          :integrations="integrations"
          :predefinedIntegrations="predefinedIntegrations"
          :isInDialog="true"
        ></CustomDashboardTable>
      </div>
    </dialog-component>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import HelperMixins from "@/mixins/HelperMixins.js";
export default {
  mixins: [HelperMixins],
  data() {
    return {
      tableVisible: false,
      showFullTable: false,
      iconBgColor: "",
      iconColor: "",
      missingData: [],
      columns: [],
      actions: [],
      integrationDetails: null,
      openIntegrationEdit: false,
    };
  },
  computed: {
    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile",
    ]),
    ...mapGetters("company", [
      "getCompanyDetails",
      "getUpdateCompany",
      "getCompanyLogo",
      "getContactLoginSlug",
    ]),
    ...mapGetters("auth", ["getDefaultDateFormat", "getAuthenticatedUser"]),
    ...mapGetters("applicationUsersPermission", ["getEntityPermission"]),
    isCurrencyStat() {
      return (
        this.component &&
        ["sum", "FORMULA"].includes(this.component.display_type) &&
        this.component.is_currency
      );
    },
    isApplicationUser() {
      return (
        this.$route.name == "entity-custom-dashboard-config" ||
        this.$route.name == "application-user-dashboard" ||
        this.$route.name == "application-user-page-dashboard"
      );
    },
    maxHeight() {
      return this.showFullTable ? "95vh" : "auto";
    },
    getComparedPercentage() {
      return (
        ((this.component.count - this.component.compare_count) /
          this.component.compare_count) *
        100
      ).toFixed(2);
    },
  },
  props: {
    name: String,
    count: Number,
    entity_id: String,
    filter_id: String,
    component: Object,
    index: Number,
    hide_options: Boolean,
    customFilters: Array,
    isFromApplicationuser: Boolean,
    hasViewPermission: Boolean,
    hasEditPermission: Boolean,
    font_color: String,
    addStatsData: Object,
    allDashboardstats: Array,
    integrations: Array,
    predefinedIntegrations: Array,
    getAllEntities: Array,
    getAllCompanyTemplatesData: Object,
    menuItems: Array,
    isIntegrationEdit: Boolean,
  },
  components: {
    CustomDashboardTable: () => import("./customDashboardTable.vue"),
    CustomTable: () => import("@/components/widgets/CustomTable.vue"),
    IntegrationConfiguration: () => import("./integrationConfiguration.vue"),
  },
  mounted() {
    //at application users side using company slug
    if (this.getContactLoginSlug) {
      this.iconBgColor = this.getContactLoginSlug.button.background;
      this.iconColor = this.getContactLoginSlug.icon_color;
    }
    //at Owner side configuration for Application users
    if (this.getCompanyDetails) {
      this.iconBgColor = this.getCompanyDetails.button.background;
      this.iconColor = this.getCompanyDetails.icon_color;
    }
    this.selectedIntegration();
  },
  methods: {
    selectedIntegration() {
      if (
        this.component.data_source === "INTEGRATIONS" &&
        this.integrations.length > 0
      ) {
        const matchingIntegration = this.integrations.find(
          (integration) => integration._id === this.component.integration_id
        );
        if (matchingIntegration) {
          let application = this.predefinedIntegrations.find(
            (predefinedIntegration) =>
              predefinedIntegration.key == matchingIntegration.application
          );
          if (application) {
            return {
              ...application,
              integration: matchingIntegration,
            };
          }
        }
      }
      return null;
    },
    closeDialog() {
      this.tableVisible = false;
      this.$emit("disableDrag", false);
      if (this.showFullTable) {
        this.showFullTable = false;
      }
    },
    getParentPath(selectedCategory) {
      if (selectedCategory == "default" || !selectedCategory) {
        if (this.iconColor == "White") {
          return "menuApplicationUsers" + "White";
        } else {
          return "menuApplicationUsers";
        }
      } else {
        if (this.iconColor == "White") {
          return selectedCategory + "-" + "White";
        } else {
          return selectedCategory + "-" + "black";
        }
      }
    },
    expendTable() {
      this.showFullTable = !this.showFullTable;
    },
    editStat() {
      this.$emit("editStat", this.index);
    },
    integrationEdit() {
      this.openIntegrationEdit = true;
    },
    deleteStat() {
      this.$confirm("Are you sure to delete the stat?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.$emit("deleteStat", this.index);
      });
    },
    openTableDialog() {
      if (
        this.component?.display_type == "FORMULA" ||
        this.component?.display_type == "TIME_STAMP" || 
        this.component?.integrationsSettings?.event?.settings?.supportType == 'stat'
      ) {
        return this.$notify({
          title: "Info",
          message: `we cant view the data related to ${this.component.display_type.toLowerCase()} in stats`,
        });
      }
      this.integrationDetails = null;

      if (this.component?.data_source === "INTEGRATIONS") {
        // let matchedApplication = this.selectedIntegration();
        // if (this.component.event && matchedApplication?.events) {
        //   const eventKey = this.component.event;
        //   const matchingEvents = matchedApplication.events.find(
        //     (event) => event.eventKey === eventKey
        //   );
        //   if (
        //     !matchingEvents?.settings?.supportType ||
        //     matchingEvents?.settings?.supportType === "stat"
        //   ) {
        //     return this.$notify({
        //       title: "Info",
        //       message: "This event does not support the table view.",
        //     });
        //   } else {
        //     this.integrationDetails = {
        //       matchedApplication,
        //       matchingEvents,
        //     };
        //   }
        // }
      }
      // if (this.component?.data_source == "INTEGRATIONS") {
      //   return this.$notify({
      //     title: "Info",
      //     message:
      //       "You can't view the data related to other applications in stats",
      //   });
      // }
      this.$emit("disableDrag", true);
      if (this.component?.show_data_in_table) {
        this.$emit(
          "openStatDataIntable",
          this.component.keyValue,
          this.integrationDetails
        );
      } else {
        if (this.component.display_type == "MISSING_SLOTS") {
          this.columns = this.generateColumns(
            this.component,
            this.getAllCompanyTemplatesData
          );
          this.missingData = this.generateMissingData(
            this.component,
            this.columns
          );
          if (
            this.component?.other_entity_id &&
            this.component.other_entity_id.includes("#")
          ) {
            let action = {
              type: "ADD",
              label: "Add new data",
              entity_id: this.component.other_entity_id.split("#")[0],
              other_info: this.component,
            };
            if (this.isApplicationUser) {
              let item = (this.menuItems || []).find((e) =>
                (e?.formbuilder_id?.steps || []).find(
                  (s) =>
                    s.entity == this.component.other_entity_id.split("#")[0]
                )
              );

              if (item?.formbuilder_id?._id) {
                this.actions = [
                  {
                    type: "FORM_ADD",
                    label: "Add new data",
                    url: `/ap/fbd/${item.formbuilder_id.code}/${item.formbuilder_id.steps[0].slug}/${item.formbuilder_id._id}?user_id=${this.getAuthenticatedUser._id}`,
                    other_info: this.component,
                    entity_id: this.component.other_entity_id.split("#")[0],
                  },
                ];
              } else {
                if (this.component?.is_relational_data) {
                  if (
                    this.getEntityPermission?.relational_entities &&
                    this.getEntityPermission?.relational_entities[
                      this.component.other_entity_id.split("#")[0]
                    ]?.permissions?.indexOf("ADD") !== -1
                  ) {
                    this.actions = [action];
                  }
                } else if (
                  this.getEntityPermission?.other_entities &&
                  this.getEntityPermission?.other_entities[
                    this.component.other_entity_id.split("#")[0]
                  ]?.permissions?.indexOf("ADD") !== -1
                ) {
                  this.actions = [action];
                }
              }
            } else {
              this.actions = [action];
            }
          }
          this.tableVisible = true;
        } else {
          this.tableVisible = true;
        }
      }
    },
    refreshStat() {
      this.$emit("refreshStat", this.component, this.index);
      this.openIntegrationEdit = false;
    },
  },
};
</script>
<style scoped lang="scss">
.Title_sty {
  font-size: 12px;
  margin-left: -20px;
  margin-top: -10px;
  font-weight: 700;
}
.stat-body {
  .action_sty {
    //  margin-left:-200px;
    margin-top: -10px;
    font-size: 10px;
    visibility: hidden;
  }
  &:hover {
    .action_sty {
      visibility: visible !important;
    }
  }
}
.iconStyle {
  width: 25%;
  margin-left: 100px;
  margin-top: -12px;
  @media (max-width: 399.98px) {
    width: 36px;
    margin-top: -19px;
  }
}
.number_c_sty {
  margin-top: -31px;
  margin-left: -10px;
  font-weight: 800;
}
.iconStyleApp {
  margin-left: 70px;
  margin-top: -12px;
}
</style>
